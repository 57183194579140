<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{ $t('events.filters.title') }} ({{ $t('events.filters.click_to_open') }})
                        </button>
                    </h2>

                    <!-- Filter  -->
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadEvents(1, values)" :validation-schema="schema" ref="eventsForm">
                                <div class="row">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="vehicleRegistrationFilter" class="form-label"> {{ $t('events.filters.vehicle_registration') }} </label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" v-model="filters.registration" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback"/>
                                    </div>
                                </div>
                                <div class="row m-t-xs">
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="startDateFilter" class="form-label">{{ $t('events.filters.start_date') }}</label>
                                        <Field type="date" name="start_date" class="form-control" id="startDateFilter" v-model="filters.start_date"/>
                                        <ErrorMessage name="start_date" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="endDateFilter" class="form-label">{{ $t('events.filters.end_date') }}</label>
                                        <Field type="date" name="end_date" class="form-control" id="endDateFilter" v-model="filters.end_date"/>
                                        <ErrorMessage name="end_date" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="startTimeFilter" class="form-label">{{ $t('events.filters.start_time') }}</label>
                                        <Field type="time" name="start_time" class="form-control" id="startTimeFilter" v-model="filters.start_time"/>
                                        <ErrorMessage name="start_time" class="error-feedback"/>
                                    </div>
                                    <div class="col-md-3 col-xs-6 col-xxs-12">
                                        <label for="endTimeFilter" class="form-label">{{ $t('events.filters.end_time') }}</label>
                                        <Field type="time" name="end_time" class="form-control" id="endTimeFilter" v-model="filters.end_time"/>
                                        <ErrorMessage name="end_time" class="error-feedback"/>
                                    </div>
                                </div>
                                <div class="row m-t-md" style="justify-content:center;">
                                    <div class="" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <!-- Show spinning icon when dataTable is loading -->
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>
                                    <div class="col-12 width-auto" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="clearFilter">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Page Heading -->
            <h1 class="table-heading"> {{ $t('events.events')}} </h1>

            <!-- Pagination -->
            <Pagination v-if="!loading" :showTotal="false" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>

            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- Event Card -->
            <div v-show="!loading" class="row events-container">
                <div v-show="events.length < 1" class="flex-center no-data-container">
                   <p> No events </p>
                </div>
                <div v-for="event in events" :key="'events-' + event.id" class="card" style="width:400px; height:auto; cursor:pointer;">
                    <div class="image-container">
                        <EventImage 
                            v-if="event.image !== null"
                            :filename="event.image"
                            :site_id="1"
                            :id="'eventimage-' + event.id"
                            :thumb="false"
                        />
                    </div>
                    <div class="event-information-container">
                        <div class="information-sub-container">
                            <span id="site-name"> {{ event.site.name + ':'}} </span>
                            <!-- <div class="status-container"> <span class="status-container"> {{ event.status}} </span> </div> -->
                             <!-- <span class="status-container"> {{ event.status.toUpperCase()}} </span> -->
                        </div>
                        <div class="information-sub-container">
                            <span id="camera-name"> {{ event.camera.name }} </span>
                        </div>
                        <div class="information-sub-container">
                            <span id="camera-direction"> {{event.direction === 'reverse' ? this.$t('events.filters.leaving_site') : this.$t('events.filters.entering_site')}} </span>
                            <Timestamp :time="event.created_at" format="dd/MM/yyyy HH:mm:ss" id="timestamp"/>
                        </div>
                        <div class="information-sub-container btn-container">
                            <span class="vrm bold number-plate-gbr" style="font-size: 16px;">{{event.vehicle.plate}}</span>
                            
                        </div>
                    </div>

                </div>
            </div>

            <!-- Pagination -->
            <Pagination v-if="!loading && events.length > 0" :showTotal="false" :config="pagination" @search="(page) => loadEvents(page, currentParams)"/>

        </div>
    </div>

    <teleport to="body">
        <div class="modal fade" id="eventImageModal" tabindex="-1" style="display: none; padding-right: 17px;" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered" style="height: 80vh; width: 75%; max-width: 75%;">
            <div class="img-magnifier-container">
                <EventImage
                    v-if="currentEvent !== null" 
                    :thumb="false" :filename="currentEvent.image" 
                    :site_id="1" 
                    :id="'modaleventimage-' + currentEvent.id"
                />
            </div>
            <p class="closePopup">
            {{ $t('events.press') }} <code>ESC</code> {{ $t('events.event_close') }}
            </p>
        </div>
        </div>
    </teleport>

</template>

<script>
//Components
import {Form, Field, ErrorMessage} from 'vee-validate';
import Pagination from '../../components/Pagination.vue';
import EventImage from '../../components/EventImage.vue';
import Timestamp from '../../components/Timestamp.vue';
import { Multiselect } from 'vue-multiselect';

//Dependencies 
import * as yup from 'yup';
import authHeader from '../../services/auth-header';
import axios from 'axios';

export default {
    name: 'EventsNew',
    components: {Form, Field, ErrorMessage, Pagination, EventImage, Timestamp, Multiselect},
    props: {
        registration: String,
        site_id: String | Number,
        start_date: String,
        end_date: String,
        start_time: String,
        end_time: String,
        direction: String,
        status: String
    },
    data() {
        const schema = yup.object().shape({
            registration: yup.string().optional().min(3, 'Must be at least 3 characters!').max(12, 'Must not be more than 12 characters!').optional().nullable(),
            start_date: yup.date().optional().nullable(),
            end_date: yup.date().optional().nullable(),
            start_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time').optional().nullable(),
            end_time: yup.string().matches('^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$', 'Must be a valid HH:mm time').optional().nullable()
        })
        return {
            loading: true,   
            events: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            schema,
            filters: {
                registration: null,
                start_date: null,
                end_date: null,
                start_time: null,
                end_time: null
            },
            sites: [],
            cameras: [],
            search: {
                client_id: null
            },
            currentParams: {},
            currentEvent: null,
        }
    },
    mounted() {

        if(this.isValid(this.status)) {
            this.filters.status = this.status;
        }
        if(this.isValid(this.start_date)) {
            this.filters.start_date = this.start_date;
        }
        if(this.isValid(this.end_date)) {
            this.filters.end_date = this.end_date;
        }

        if(this.status && this.start_date && this.end_date) {
            this.loadEvents(1, this.filters);
            return;
        }
        this.loadEvents();
    },
    methods: {
        modalImageLoaded(){
            console.log("Modal image loaded");
        },
        loadEvents(page = 1, params = {}) {
            console.log(params);
            this.loading = true;
            this.currentParams = params;
            let url = 'https://server.varsanpr.com/api/nbcs/events';
            let queryParams = {
                page: page
            }
            if(params.registration) {
                queryParams.registration = params.registration;
            }
            if(params.start_date) {
                queryParams.start_date = params.start_date;
            }
            if(params.end_date) {
                queryParams.end_date = params.end_date;
            }
            if(params.start_time) {
                queryParams.start_time = params.start_time;
            }
            if(params.end_time) {
                queryParams.end_time = params.end_time;
            }
            axios.get(`https://server.varsanpr.com/api/nbcs/events`, {
                params: queryParams,
                headers: authHeader(),
            })
            .then(response => {
                this.events = response.data.events;
                this.pagination = response.data.pagination;
            })
            .catch(error => {
                this.$error("Failed to load events", error);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        clearFilter() {
            // resetForm();
            // this.loadEvents(1, {});
            this.filters.registration = null;
            this.filters.start_date = null;
            this.filters.end_date = null;
            this.filters.start_time = null;
            this.filters.end_time = null;
        },
        openEventImage(event) {
            this.currentEvent = event;
            $('#eventImageModal').modal('toggle');
            // console.log(document.getElementById(`eventimage-${this.currentEvent.id}`));
            // this.magnify(3);
        },
        isValid(value) {
            if(value === null) return false;
            if(value === undefined) return false;

            if(typeof value === 'string') {
                if(value.length < 1) return false;
            }

            return true;
        }
    },
}
</script>

<style scoped>

.events-container {
    margin-top: 20px;
    justify-content: space-between;
}

/* also in custom.css */
.card {
    width: 400px;
    border-radius: 10px;
    /* background-color: #232330; */
    cursor: pointer;
}

.card:hover {
    box-shadow: 5px 5px 5px rgba(255, 255, 255, 0.1);
}

.image-container {
    margin: 10px 0;
    border-radius: 10px 10px 0 0;
}

.event-information-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.event-information-container div {
    margin: 5px 0;
}

#site-name {
    font-size: 18px;
    font-weight: 500;
}

#camera-name, #camera-direction, #camera-direction, #timestamp {
    font-size: 16px;
}

.information-sub-container {
    display: flex;
    justify-content: space-between;
}

.status-container {
    border: 2px solid var(--utility-green); 
    border-radius: 50px;
    padding: 5px 10px;
    color: var(--utility-green);
    font-weight: 400;
}

/* .information-sub-container button {
    background-color: rgba(220, 53, 69, 0.3);
    border: 2px solid transparent;
    color: var(--utility-red);
} */

.information-sub-container button:hover {
    border: 2px solid var(--utility-red);
}

.btn-container {
    margin-top: 10px !important;
}

.red {
    color: var(--utility-red);
    background-color: rgba(220, 53, 69, .3);
}

.green {
    color: var(--utility-green);
    background-color: rgba(75, 173, 72, .3);
}

.red, .green {
    border-radius: 50px;
    padding: 5px 10px;
    font-weight: 600;
}

/* .secondary-blue-btn {
    background-color: rgba(34, 105, 245, .3) !important;
    color: var(--utility-blue) !important;
} */

.secondary-blue-btn:hover {
    border-color: var(--utility-blue) !important;
}

.blacklist-card {
    border: 2px solid var(--utility-red);
}

.no-data-container {
    color: var(--utility-orange);
    font-style: italic;
    font-size: 16px;
    font-size: bold;
}

/* .modal-sub-title {
    font-weight: 100;
    color: #CDC4D1;
} */

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  width: 100px;
  height: 100px;
}

.warning-icon {
    font-size: 50px;
    color: var(--utility-orange);
}

.flex {
    flex-wrap: nowrap;
    /* padding: 10px 0; */
}

</style>